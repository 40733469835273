:root{
    --brown: #33251f;
    --stroke: #9c815a;
    
}

.loadinContainer{
    width: 100%;
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 99999999999;
    position: fixed;
    background-color: white;
}