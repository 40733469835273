/* google fonts */
@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@300;400;500;600;700;800&display=swap");

/*  css variables */
:root {
  --card-bg-01: #fdefe6;
  --card-bg-02: #d6e5fb;
  --card-bg-03: #ceebe9;
  --card-bg-04: #e2f2b2;
  --primary-color: radial-gradient(circle, rgba(98,69,28,1) 0%, rgba(55,31,22,1) 100%);
  --hero-bg: #d6e5fb;
  --heading-fontSize: 2rem;
  --small-text-color: #999;
  --heading-text-color: #46281D;
  --stroke-color: #e8c99d;
  --desktop-size: 18px;
  --tablet-size: 16px; 
  --mobile-size: 14px
}

.Loading{
  margin: 20px auto;
}
/* reset browser default */
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
body {
  overflow-x: hidden!important;
  font-family: "Montserrat", sans-serif;
}
h1,
h2,
h3,
h4,
h5,
h6,
p {
  padding: 0;
  margin: 0;
}
p {
  color: var(--small-text-color);
  font-size: 1rem;
}
h1,
h2 {
  font-size: var(--heading-fontSize);
}
ul {
  list-style: none;
}
a {
  text-decoration: none;
  color: unset;
}
section {
  padding: 60px 0px;
}

.Loading{
  margin: 200px auto;
  text-align: center;
}

body,html{
  scroll-behavior: smooth;
}
.immendetly-call{
  position: fixed;
  z-index: 999999;
  color: white;
  font-size: xx-large;
  background: var(--primary-color);
  right: 0;
  margin: 30px 30px;
  padding: 15px;
  box-sizing: content-box;
  border-radius: 100%;
  bottom: 0;
  cursor: pointer;
  animation: call-icon-animate 0.7s infinite alternate;
  border: 1px solid var(--stroke-color);
}

.immendetly-call:hover{
  color: lightgreen;
}

@keyframes call-icon-animate {
  0%{
    transform: scale(1.1) rotate(20deg);
    filter: none;
  }
  50%{
  }
  100%{
    transform: scale(1) rotate(0deg);
    filter: drop-shadow(0px 0px 7px var(--primary-color));
  }
}

@media screen and (max-width: 768.99px) {
  .immendetly-call{
    padding: 10px;
  }
}