.Categories_container,.containerSubCategories {
    height: 100%;
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-bottom: 100px;
}

.containerCategories,.subCategoryList{
    display: flex;
    /* border-radius: 20px; */
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-around;
    align-content: space-between;
    width: 80%;
    gap: 50px;
    border: 5px double var(--stroke-color);
    padding: 50px 0px;
}
.infoLinkConter {
    width: 300px;
    height: 300px;
    overflow: hidden;
    padding: 0;
    margin: 0;
    border: 5px double var(--stroke-color);;
    position: relative;
}
.infoLinkConter h3 {
    position: absolute;
    top: 50%;
    left: 50%;
    width: 100%;
    height: 100%;
    transform: translate(-50%,90%);
    text-align: center;
    color: white;
    font-weight: bold;
    transition: 0.4s;
    background: var(--primary-color);
    opacity: .9;
}
.infoLinkConter:hover h3 {
    height: 80px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.infoLinkConter img {
    width: 100%;
    align-self: center;
    height: 100%;
    object-fit: cover;
    transition: 0.4s;
}

.infoLinkConter:hover img {
    scale: 1.1;
}


.containerCategories_products{
    display: flex;
    background: var(--primary-color);
    padding-left: 10px;
    padding-right: 10px;
    overflow-x: auto;
    gap: 20px;
    padding-bottom: 20px;
}

.containerCategories_products::-webkit-scrollbar{
    width: 15px;
    height: 15px;
}

.containerCategories_products::-webkit-scrollbar-thumb {
    background: silver;
   border-radius: 5px;
 }

 .containerCategories_products::-webkit-scrollbar-track:horizontal {
    background-color: white;
    box-shadow: inset 0 0 2px 2px gainsboro;
  }


  @media screen and (max-width: 768.99px) {
    .infoLinkConter h3 {
        height: 60px;
        display: flex;
        font-size: larger;
        align-items: center;
        justify-content: center;
        transform: translate(-50%,100%);
    }

    .infoLinkConter {
        width: 250px;
        height: 250px;
    }
}

@media screen and (max-width: 450.99px) {
    .infoLinkConter {
        width: 200px;
        height: 200px;
    }
    .infoLinkConter h3 {
        height: 60px;
        display: flex;
        align-items: center;
        justify-content: center;
        transform: translate(-50%,60%);
    }
}

@media only screen and (max-width: 768.99px) {
    .infoLinkConter,.infoLinkConter:hover h3  {
      pointer-events: none; /* Disables all pointer events, including hover */
    }
}