.footer-container footer{
    height: 35vh;
    background: var(--primary-color);
    display: flex;
    align-items: center;
    justify-content: center;
    overflow: hidden;
    border-top: 5px double var(--stroke-color);
}

.footer-wrap{
    height: 100%;
    width: 80%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.about-company{
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: flex-start;
    padding: 0;
    margin: 0;
}
.footer_text{
    margin: 5px !important;
    color: white;
}

.footer_logo{
    width: 200px;
}

.footer_social,.footer_contact{
    width: 70%;
    padding: 0 !important;
    margin: 0 !important;
}

.footer_social{
    display: flex;
    font-size: xx-large;
    align-items: center;
    justify-content: center;
    gap: 40px;
}

.footer_contact ul{
    display: flex;
    margin: 0;
    padding: 0;
    align-items: center;
    justify-content: space-around;
    font-size: large;
    color: var(--stroke-color);
    gap: 20px;
}
.footer_contact{
    border-top: 1px solid rgba(255, 255, 255, 0.349);
}

@media screen and (max-width: 768px) {
    .footer-wrap{
        width: 100%;
    }
    .footer-container footer{
        height: fit-content;
    }
    
}


@media screen and (max-width: 450px) {
    .footer_text{
        font-size: small;
        width: 50%;
    }
    .footer_contact ul{
        flex-direction: column;
        font-size: small;
    }
    
    .about-company{
        font-size: small;
        text-align: center;
    }
}