.mainSliderContainer{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.list-indicator{
    padding: 5px 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 5px;
    background: #a496811d
}

.list-indicator li img {
    cursor: pointer;
    width: 70px;
    height: 70px;
}

.slider-container,.mainSliderContainer {
    width: 100%;
    height: fit-content;
}

.slider-container .carousel-item {
    width: 100%;
    height: fit-content;
}

.slider-container .carousel-item img {
    object-fit: contain;
    width: 100%;
    min-height: 400px;
    max-height: 700px;
    
}

@media screen and (max-width: 425px) {
    .list-indicator{
        padding: 0;
        margin: 0;
        inset: 0;
    }
}

@media screen and (min-width: 2000px) {
    .list-indicator li img {
        width: 100px;
        height: 100px;
    }
}