#getListPosition{
  width: 100%;
  margin-top: 50px;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-self: center;
  align-items: center;
  justify-content: center;
  transition: all 0.4s;
}

.list-wrapper{
  padding: 20px;
  width: 80%;
  border: 5px double var(--stroke-color);
  height: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: center ;
  align-items: center;
  column-count: 2;
  transition: all 0.4s;
}

.product_item{
  display: flex;
  flex-direction: column;
  width: 300px;
  margin: 20px;
  cursor: pointer;
  transition: all 0.4s;
  border: 10px double var(--stroke-color);
}

.product-image-container{
  width: 100%;
  height: 100%;
  position: relative;
  border: 1px solid var(--primary-color);
}

.product_item:hover img{
  transform: scale(1.1);
}

.product-image-container span {
  position: absolute;
  font-size: x-large;
  background-color: rgb(255, 61, 61);
  display: flex;
  z-index: 3;
  filter: drop-shadow(1px 2px 15px rgb(255, 61, 61));
  align-items: center;
  justify-content: center;
  color: var(--stroke-color);
  padding: 0px 10px 10px 0px;
  border-radius: 0px 0px 10px 0px;
  font-weight: bold;
}

.product-image-container{
  overflow: hidden;
}

.product-image-container img{
  width: 100%;
  height: 300px;
  object-fit: cover;
  transition: all 0.4s;
}

.prodict-info{
  display: flex;
  flex-direction: column;
  font-weight: bold;
}
.prodict-info span{
  padding-left: 7px;
}
.prodict-info button{
  width: 100%;
  height: 40px;
  border: 0;
  background: var(--primary-color);
  color: white;
  font-weight: bold;
}

.prodict-info button:hover{
  background-color: var(--stroke-color);
  border: 1px solid var(--primary-color);;
}



/***** MEDIA *****/

@media only screen and (max-width: 768.99px) {
  .product_item{
    margin: 20px;
    width: 300px;
  }
  .product-image-container img{
    height: 300px;
    width: 100%;
  } 
  .product-image-container{
    width: 100%;
  }
}

@media only screen and (max-width: 450.99px) {
  .product_item{
    margin: 20px 0px;
    width: 250px;
  }
  .product-image-container img{
    height: 200px;
    width: 100%;
  } 
  .product-image-container{
    width: 100%;
  }
}