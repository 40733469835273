.lang-container{
    display: flex;
    align-items: center;
    gap: 5px;
    border: 1px double var(--stroke-color);
    padding: 3px;
    border-radius: 5px;
}

.lang-container select{
    padding: 0;
    border: 0;
    margin: 0;
    background-color: transparent;
    color: white;
}

.lang-container select:focus{
    outline: none;
}

.opt{
    color: black !important;
}

.selected-lang{
    width: 30px;
    height: 20px;
    display: flex;
    align-items: center;
    align-self: center;
}

.selected-lang img {
    width: 100%;
    object-fit: cover;
    height: 100%;
}