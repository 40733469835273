header{
    position: sticky;
    left: 0;
    top: 0;
    z-index: 11;
    width: 100%;
    height: 70px;
    display: flex;
    line-height: 70px;
    background-color: #fff;
    border-bottom: 5px double var(--stroke-color);
    background: var(--primary-color);
}
.mob-menu-container{
    display: none;
}
.nav_wrapper{
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-around;
}
.LogoParent{
    display: flex;
    align-items: center;
    justify-content: center;
    width: 176px;
   height: 70px;
   overflow: hidden;
   
}

.navigation{
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 20px;
    padding: 0;
    margin: 0;
}

.navigation-mob{
    display: none;
}
.language-bar-mob{
    display: none;
}
.Logo img{
    width: 150px;
} 

.navItem a {
    color: white;
    font-weight: 600;
    cursor: pointer;
}
.nav_icons{
    display: flex;
    align-items: center;
    column-gap: 1.2rem;
}
.nav_icons span i{
    font-size: 1.2rem;
    cursor: pointer;
}
.cart_icon, .fav_icon{
    position: relative;
}
.badge{
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 15px;
    height: 15px;
    top: 25%;
    left: 10px;
    content: '';
    color: #fff;
    border-radius: 100%;
    font-size: 0.7rem;
    font-weight: 600;
    z-index: 10;
}
.nav_icons .profile img{
    width: 30px;
    height: 30px;
    cursor: pointer;
    border-radius: 100%;
}
.nav_icons .profile{
    position: relative;
    z-index: 9;
}
.profile_actions{
    position: absolute;
    display: flex;
    align-items: center;
    flex-direction: column;
    top: 98%;
    left: -80px;
    width: 150px;
    z-index: 10;
    padding: 15px;
    background-image: linear-gradient(rgb(136, 129, 129),rgb(75, 69, 69),rgb(20, 17, 17));
    line-height: 30px;
     display: none;
    cursor: pointer;
    color: #fff;
}
.show_profileAtions{
    display: block;
}

.cart_icon{
    cursor: pointer;
}

.cart_icon svg {
    fill: white;
    font-size: large;
}

.mobile_menu{
    font-size: 1.2rem;
    color: var(--primary-color);
    display: none;
}
.nav_active{
    padding-bottom: 5px;
    transition: 0.2s;
    border-bottom: 3px solid var(--stroke-color) !important;
}
.sticky_header{
    width: 100%;
    height: 70px;
    line-height: 70px;
    position: sticky;
    top: 0;
    left: 0;
    z-index: 999;
    box-shadow: 3px 3px 8px -3px #222020;
    background-color: #fff;
}
/***** MEDIA *****/
@media screen and (max-width: 768px) {
    header{
        height: 80px;
        transition: 0.4s;
        display: flex;
        overflow: hidden;
        flex-direction: column;
        align-items: center;
    }
    .navigation{
        display: none;
    }
    .navigation-mob{
        display: flex;
        align-items: center;
        flex-direction: column;
        width: 95%;
        justify-content: space-between;
    }
    .mob-menu-container{
        display: block;
    }
    .nav_wrapper{
        justify-content: space-between;
        padding: 0px 20px;
        margin: 0;
    }
    .language-bar{
        display: none !important;
    }
    .language-bar-mob{
        display: block;
    }
}




@media screen and (max-width: 425px) {
    header{
        transition: all 0.4s;
    }
    .navigation-mob{
        display: flex;
        align-items: center;
        flex-direction: column;
        width: 95%;
        justify-content: space-between;
    }
}








