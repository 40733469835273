.home-container{
   height: 100%;
   margin-bottom: 100px;
}

.intro_container{
   width: 100%;
   height: fit-content;
   border-bottom: 5px double var(--stroke-color);
   overflow: hidden;
}


.list-title{
   width: 100%;
   display: flex;
   align-items: center;
   justify-content: center;
   margin-top: 50px;
}

.list-title h1 {
   background: var(--primary-color);
   color: white;
   font-weight: bold;
   display: inline;
   padding: 7px;
}

.hero_section h2 {
   color: var(--primary-color);
   font-size: 2.5rem;
   font-weight: 600;
   margin: 20px 0;
}
.hero_section p {
   color: var(--primary-color);
   line-height: 28px;
}
.hero_section p:nth-child(1) {
   font-weight: 500;
}
.buy_btn {
   border: none;
   outline: none;
   padding: 8px 20px;
   border-radius: 5px;
   background: var(--primary-color);
   color: #fff;
   cursor: pointer;
   font-size: 1rem;
}
.hero_section .hero_content {
   padding-top: 45px;
}
.language-bar {
   display: flex;
   grid-gap: 0.4rem;
}
.language-bar>button {
   height: 25px;
   width: 25px;
   border-radius: 50%;
   display: flex;
   align-items: center;
   justify-content: center;
   border: none;
}
.language-bar>#am {
   background: url('../assets/images/am.png');
   background-size: cover;
   background-repeat: no-repeat;
}
.language-bar>#ru {
   background: url('../assets/images/ru.png');
   background-size: cover;
   background-repeat: no-repeat;
}
.language-bar>#en {
   background: url('../assets/images/us.png');
   background-size: cover;
   background-repeat: no-repeat;
}


.about_artWork{
   margin-top: 50px;
   border: 5px double var(--stroke-color);
   border-radius: 30px;
   width: 80%;
   gap: 50px;
   padding: 50px;
   display: flex;
   align-items: center;
   align-self: center;
}

.home-container{
   display: flex;
   flex-direction: column;
   align-items: center;
}

.abtusimg{
   display: flex;
   align-items: center;
   justify-content: center;
   width: 40%;
   padding: 0 !important;
   height: 100%;
   position: relative;
}



.abtusimg>img{
   width: 60%;
   padding: 30px 0px;
   border: 10px double var(--stroke-color);
   border-left: 0;
   border-right: 0;
   object-fit: contain;
}

.about_artWork{
   position: relative;
}
.about_artWork span img{
   position: absolute;
   width: 10%;
   filter: drop-shadow(1px 1px 0px var(--stroke-color));
}

.about_artWork span:nth-child(1) img {
   top: 0;
   left: 0;
}

.about_artWork span:nth-of-type(2) img {
   bottom: 0;
   right: 0;
   transform: rotateX(180deg) rotateY(180deg);
}

.aboutTxt{
   width: 50%;
   color: var(--primary-color);
   padding: 0;
   font-size: var(--desktop-size);
}


@media screen and (max-width: 1050px) {
   .aboutTxt{
      font-size: var(--tablet-size);
   }
}

 

@media screen and (max-width: 768px) {
   .about_artWork span img{
      display: none;
   }
   .intro_container{
      width: 100%;
      height: auto;
      background: var(--primary-color);
   }
   .abtusimg{
      width: 90%;
   }
   
   .aboutTxt{
      font-size: var(--mobile-size);
      width: 90%;
      text-align: center;
   }
   .about_artWork{
      width: 100%;
      padding: 0;
      flex-direction: column;
      justify-content: center;
   }
}



@media (max-width: 375px) {
   .list-title h1 {
      font-size: x-large;
   }   
 }
 

