
.cart-container{
    height: fit-content;
}
.ProductAOrder{
    margin-top:100px;
}

.ProductAOrderContent{
    margin: auto;

}

.containerInfo{
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    margin: auto;
    margin-top: 50px;
}

.table tr td img{
    width: 80px;
    height: 80px;
    object-fit: cover;
    align-items: center;
}
td i {
    cursor: pointer;
}
td{
    color: var(--primary-color);
}



.cartConter button{
    margin-top: 30px;
}
.info{
    min-width: 90%;
    margin: 30px auto;
    text-align: center;
    line-height: 30px;
}
.info h3{
    color: red;
    font-weight: 900;
}
.info p{
    color: black;
    font-size: 18px;
}
.visible-message{
    display: block;
}
.unvisible-message{
  display: none !important;
}
.text-green{
    color: green;
}
.text-red{
    color: red;
}

.sub_nav{
    margin-top: 30px;
    margin-bottom: 20px;
    display: flex;
    width: 50%;
    font-size: larger;
    justify-content: space-around;
}

.buyBtn{
    margin-top: 40px;
}

.txtBg{
    background: var(--primary-color);
    padding: 7px;
    font-size: x-large;
    font-weight: bold;
    color: white;
}

.txtBg:hover{
    color: white;
}


@media screen and (max-width: 768px) {
    .txtBg{
        font-size: large;
        text-align: center;
    }
    .sub_nav{
        gap: 15px;
    }
}


@media screen and (max-width: 450px) {
    tbody,thead,.sub_nav{
        zoom: 70%;
    }
}
