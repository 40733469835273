.carousel-container{
    width: 100%;
    height: fit-content;
    display: flex;
    align-items: center;
   justify-content: center;
}

.carousel-container video {
    width: 100%;
    height: 50vh;
    object-fit: cover;
}

@media screen and (max-width: 550px) {
    .carousel-container video {
        height: 35vh;
    }
}