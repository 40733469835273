.productDetailsContainer{
  margin-top: 30px;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  min-height: 120vh;
  height: 100%;
  margin-bottom: 50px;
}


.product_detalis{
  width: 50%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 20px;
  padding-right: 10px;
}

.ProductSliderContain{
  display: flex;
  height: 100%;
  overflow: hidden;
  width: 50%;
}

.section1{
  display: flex;
  flex: 0.7;
  align-items: center;
  padding: 0;
  gap: 20px;
  flex-direction: row-reverse;
  width: 100%;
  border: 5px double var(--stroke-color);
}
.section2{
  padding: 0;
  flex: 0.5;
  width: 60%;
  align-self: center;
  display: flex;
  flex-direction: column;
  gap: 50px;
  align-items: center;
  justify-content: center;
  height: fit-content;
}

.importantRatting{
  text-align: center;
}

.about-product-container{
  display: flex;
  flex-direction: column;
  width: 100%;
}

.comments-section{
  width: 100%;
}

.desc{
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 50px;
}
.tab_content{
  margin: 0 !important;
}
.review_wrapper ul {
  padding: 0;
}

.tab_wrapper{
  display: flex;
  flex-direction: column;
  align-items: center;
}

.tab_wrapper{
  cursor: pointer;
}
.tab_wrapper h6 label{
  color: rgb(12, 189, 248);
  text-decoration: underline;
  cursor: pointer;
}


.review_form form {
  margin-top: 10px;
  display: flex;
  flex-direction: column;
  width: 100%;
  justify-content: center;
  gap: 10px;
}

.form_group input,textarea {
  width: 100%;
  height: 50px;
  padding: 3px 0px 0px 5px;
  border: 2px double var(--stroke-color);
}

.ri-star-s-fill,.ri-star-half-s-line{
  color: gold;
  font-size: x-large;
}

.rating_group{
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 80%;
}

.rating_group span{
    display: flex;
    color: gold;
    align-items: center;
    cursor: pointer;
}



@media screen and (max-width: 768.99px) {
  .section1{
    flex-direction: column-reverse;
    align-items: center;
  }
  .product_detalis{
    width: 90%;
    margin-bottom: 10px;
  }
  
  .ProductSliderContain{
    width: 90%;
  }

  .section2{
    width: 90%;
  }
  .rating_group{
    width: 100%;
  }
}