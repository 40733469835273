
.services{
    display: flex;
    align-items: center;
    justify-content:center;
    width: 100%;
    gap: 100px;
}


.services_item{
    position: relative;
    display: flex;
    align-items: center;
    width: 100%;
    text-transform: uppercase;
    transition: 0.2s;
    justify-content: space-between;
    border: 5px double var(--stroke-color);
}

.services_item:hover > img {
    filter: blur(2px);
}

.services_item:hover > .service_title {
    height: 80px;
    border: 5px double var(--stroke-color);
    border-right: 0px !important;
    border-left: 0px !important;
}

.services_item img{
    max-width: 500px;
    max-height: 300px;
    width: 500px;
    height: 300px;
    object-fit: cover;
}

.service_title{
    overflow: hidden;
    transition: 0.4s;
    color: white;
    position: absolute;
    background: var(--primary-color);
    width: 100%;
    height: 0px;
    align-items: center;
    justify-content: center;
    display: flex;
    flex-direction: column;
    font-weight: bold;
}
.service_sub{
    text-transform: capitalize;
    opacity: 0.5;
    font-size: medium;
    font-weight: 100;
}
.beforeHoverTitle{
    position: absolute;
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: center;
    font-weight: bold;
    color: white;
    border: 1px solid;
    filter: drop-shadow(1px 1px 1px black);
    font-size: 55px;
    opacity: 0.8;
}


/***** MEDIA *****/


@media (max-width: 1150px) {
    .services_item img{
        width: 400px;
        height: 250px;
    }
    .beforeHoverTitle{
        font-size: 40px;
    }
}




@media (max-width: 998px) {
    .servicesConter{
        justify-content: space-around;
        grid-gap: 3rem;
    }
    .services_item img{
        width: 350px;
        height: 200px;
    }
}

@media (max-width: 850px) {
    .servicesConter{
        justify-content: space-around;
        grid-gap: 3rem;
    }
    .services_item img{
        width: 300px;
        height: 180px;
    }
}

@media (max-width: 768px) {
  .services{
    flex-direction: column;
    gap: 50px;
  }
  .services_item img{
    width: 450px;
    height: 250px;
}
}

@media (max-width: 480px) {
    .services_item img{
      width: 350px;
      height: 200px;
  }
  }

  
@media (max-width: 375px) {
    .services_item img{
      width: 300px;
      height: 150px;
  }
  }
  